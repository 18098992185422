import React, {useState} from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import uniqid from "uniqid";
import {Whiteboard} from "./Whiteboard";
import {Editor} from "./editor/Editor";

const whiteboardId = "test";

export function App() {
	const [editorData, setEditorData] = useState({lines: []});

	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/">
					<Redirect to={"/" + uniqid()}/>
				</Route>
				<Route exact path="/editor">
					<Editor
						data={editorData}
						addLine={line => setEditorData(data => {
							data.lines.push(line);
							return data;
						})}
						onChange={(id, newShapeData) => setEditorData(data => {
								data.lines
									.filter(line => line.id === id)
									.forEach(line => Object.assign(line, newShapeData));
								return data;
							}
						)}
					/>
				</Route>
				<Route path="/:whiteboardId" component={Whiteboard}/>
			</Switch>
		</BrowserRouter>

	);
}