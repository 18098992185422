import React, {useLayoutEffect, useRef} from "react";
import {Transformer} from "react-konva";


export const TransformerElement = React.forwardRef(({}, shapeRef) => {
	let transformerRef = useRef(null);

	useLayoutEffect(() => {
		transformerRef.current.getLayer().batchDraw();
		transformerRef.current.setNode(shapeRef.current);
	}, []);


	return (
		<Transformer
			ref={transformerRef}
		/>
	);
});