import React, {useCallback, useEffect, useRef, useState} from "react";
import {Circle, Layer, Stage, useStrictMode} from "react-konva";
import {css} from "@emotion/css";
import uniqid from "uniqid";
import {Button, ButtonGroup, ResizeSensor} from "@blueprintjs/core";
import {LineElement} from "./LineElement";
import {Colors} from "@blueprintjs/core/src/common/colors";
import {withRouter} from "react-router-dom";
import {TextElement} from "./TextElement";
import {Hotkeys} from "./Hotkeys";

const bgColor = Colors.LIGHT_GRAY5;
const dotColor = Colors.DARK_GRAY1;
const dotSize = 1;
const dotSpace = 22;

const style = css`
	display: grid;
	grid-rows: auto 1fr;
	width:100%;
	height: 100%;
`;

const dottedBackground = css`
	height: 100%;
	width: 100%;
	background: linear-gradient(90deg, ${bgColor} ${dotSpace - dotSize}px, transparent 1%) center, 
				linear-gradient(${bgColor} ${dotSpace - dotSize}px, transparent 1%) center, ${dotColor};
	background-size: ${dotSpace}px ${dotSpace}px;`;

useStrictMode(true);

export const Editor = withRouter(InternalEditor);

function InternalEditor({data, mousePointers, addLine, addText, onChange, removeElement, updateMousePosition}) {
	const [drawing, setDrawing] = useState(false);
	const [lastLine, setLastLine] = useState([]);
	const [mode, setMode] = useState("drawing");
	const [selectedId, setSelectedId] = useState(null);
	const stageRef = useRef();

	useEffect(() => {
		setSelectedId(null);
	}, [mode, setSelectedId]);

	useEffect(() => console.log(selectedId), [selectedId]);

	const startDrawing = useCallback((e) => {
		switch (mode) {
			case "drawing": {
				e.cancelBubble = true;
				console.log("Start drawing", e);
				let pos = e.target.getStage().getPointerPosition();
				setLastLine(l => [...l, pos.x, pos.y]);
				setDrawing(true);
				break;
			}
		}
	}, [setLastLine, setDrawing, mode]);

	let throttledSetLastLine = useCallback(throttle((x, y) => {
		setLastLine(l => [...l, x, y]);
	}, 10), [setLastLine]);

	const mouseMoved = useCallback((e) => {
		e.cancelBubble = true;
		updateMousePosition(e.target.getStage().getPointerPosition());
		if (drawing) {
			const pos = e.target.getStage().getPointerPosition();
			throttledSetLastLine(pos.x, pos.y);
		}
	}, [throttledSetLastLine, drawing]);

	const stopDrawing = useCallback((e) => {
		e.cancelBubble = true;
		if (mode !== "drawing") {
			return;
		}
		console.log("Stop drawing", e);
		addLine({
			type: "line",
			id: uniqid(),
			points: lastLine,
			x: 0,
			y: 0,
			scaleX: 1,
			scaleY: 1,
			rotation: 0,
		});
		setLastLine([]);
		setDrawing(false);
	}, [lastLine, setLastLine, setDrawing, addLine, mode]);


	const [editorSize, setEditorSize] = useState({width: 0, height: 0});
	const handleResize = useCallback((entries) => {
		let size = {
			width: entries[0].contentRect.width,
			height: entries[0].contentRect.height,
		};
		setEditorSize(size);
	}, []);

	const onClick = useCallback((e) => {
		console.log("Stage clicked");
		e.cancelBubble = true;
		switch (mode) {
			case "move": {
				const clickedOnEmpty = e.target === e.target.getStage();
				if (clickedOnEmpty) {
					setSelectedId(null);
				}
				break;
			}
			case "text": {
				if (selectedId === null) {
					let pos = e.target.getStage().getPointerPosition();
					let shape = {
						type: "text",
						id: uniqid(),
						x: pos.x,
						y: pos.y,
						scaleX: 1,
						scaleY: 1,
						rotation: 0,
						text: ""
					};
					setSelectedId(shape.id);
					addText(shape);
				} else {
					setSelectedId(null);
				}
				break;
			}
		}
	}, [mode, setSelectedId, selectedId, addText]);


	return (
		<div className={style}>
			<Hotkeys
				deleteElement={() => {
					console.log("Delete", selectedId);
					return removeElement(selectedId);
				}}
			/>
			<ButtonGroup fill large>
				<Button
					icon="edit"
					active={mode === "drawing"}
					onClick={() => setMode("drawing")}
				/>
				<Button
					icon="font"
					active={mode === "text"}
					onClick={() => setMode("text")}
				/>
				<Button
					icon="move"
					active={mode === "move"}
					onClick={() => setMode("move")}
				/>
				{navigator.share &&
				<Button icon="share"
						onClick={() => navigator.share({
							title: "Whiteboard",
							url: window.location.href
						})}
				/>}
				<Button
					icon="new-layer"
					onClick={() => window.open("/", "_blank")}
				/>
				<Button
					icon="export"
					onClick={() => window.open(stageRef.current.toDataURL({pixelRatio: 3}), "_blank")}
				/>
			</ButtonGroup>
			<ResizeSensor onResize={handleResize}>
				<Stage
					ref={stageRef}
					width={editorSize.width}
					height={editorSize.height}
					className={dottedBackground}
					onMouseDown={startDrawing}
					onTouchStart={startDrawing}
					onMouseUp={stopDrawing}
					onTouchEnd={stopDrawing}
					onMouseMove={mouseMoved}
					onTouchMove={mouseMoved}
					onClick={onClick}
					onTap={onClick}
				>
					<Layer>
						{data.lines
							.map(shape => {
								switch (shape.type) {
									case "line":
										return <LineElement
											key={shape.id}
											data={shape}
											mode={mode}
											isSelected={selectedId === shape.id}
											deselect={() => setSelectedId(null)}
											onClick={() => mode === "move" && setSelectedId(shape.id)}
											onChange={onChange}
										/>;
									case "text":
										return <TextElement
											key={shape.id}
											data={shape}
											mode={mode}
											isSelected={selectedId === shape.id}
											deselect={() => setSelectedId(null)}
											onClick={(e) => {
												console.log("Element clicked");
												e.cancelBubble = true;
												return (mode === "move" || mode === "text") && setSelectedId(shape.id);
											}}
											onChange={onChange}
										/>;
									default:
										console.log("Unknown shape:", shape);
										break;
								}
							})}
						{lastLine.length > 0 &&
						<LineElement
							data={{points: lastLine}}
						/>}
						{Object.entries(mousePointers).map(([key, value]) => (
							<Circle
								key={value.id}
								x={value.x}
								y={value.y}
								radius={5}
								fill="blue"
							/>
						))}
					</Layer>
				</Stage>
			</ResizeSensor>
		</div>
	);
}

function throttle(func, timeFrame) {
	let lastTime = 0;
	return function (x, y) {
		let now = new Date();
		if (now - lastTime >= timeFrame) {
			func(x, y);
			lastTime = now;
		}
	};
}