import React from "react";
import {GlobalHotKeys} from "react-hotkeys";

const keyMap = {
	DELETE_SELECTED: "del"
}

export function Hotkeys({deleteElement}) {
	return (
		<GlobalHotKeys
			keyMap={keyMap}
			handlers={{
				DELETE_SELECTED: deleteElement
			}}
			allowChanges={true}
		/>
	);
}