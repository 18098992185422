import React, {useCallback, useRef} from "react";
import {Line} from "react-konva";
import {TransformerElement} from "./TransformerElement";

export function LineElement({data: shapeProps, mode, isSelected, deselect, onClick, onChange}) {
	let shapeRef = useRef(null);

	const startDragging = useCallback((e) => {
		console.log("startDragging", e);
	}, []);

	const stopDragging = useCallback((e) => {
		console.log("stopDragging", e);
		onChange({...shapeProps, x: e.target.x(), y: e.target.y()});
	}, [onChange]);

	let stopTransforming = useCallback((e) => {
		let changes = {
			type: shapeProps.type,
			id: shapeProps.id,
			x: e.target.x(),
			y: e.target.y(),
			scaleX: e.target.scaleX(),
			scaleY: e.target.scaleY(),
			rotation: e.target.rotation(),
		};
		console.log("Stop Transforming", e.target.attrs, changes);
		onChange(changes);
	}, [onChange, shapeRef]);
	return (
		<>
			<Line
				ref={shapeRef}
				id={shapeProps.id}
				stroke="#df4b26"
				strokeWidth={2}
				hitStrokeWidth={10}
				lineCap="round"
				globalCompositeOperation="source-over"
				draggable={mode === "move"}
				onClick={onClick}
				onTap={onClick}
				onDragStart={startDragging}
				onDragEnd={stopDragging}
				onTransformEnd={stopTransforming}
				{...shapeProps}
			/>
			{isSelected && <TransformerElement ref={shapeRef}/>}
		</>
	);
}