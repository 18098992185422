import React, {useEffect, useState} from "react";
import Peer from "peerjs";
import {createPeerUpdatesObservable, removeDisconnectedPeers, updatePeerId} from "../datastore/firebase";
import {throttleTime, withLatestFrom} from "rxjs/operators";
import {interval, Subject} from "rxjs";

const connectedPeersSubject = new Subject();

export function usePeer2Peer(whiteboardId, newDataFromPeer, updatesForPeers) {
	const [peer, setPeer] = useState();
	const [connectedPeers, setConnectedPeers] = useState({});

	useEffect(() => {
		console.log("PeerJs ");
		let p = new Peer();

		p.on("open", function (id) {
			updatePeerId(whiteboardId, id);
			setPeer(p);
		});

		p.on("connection", conn => {
			console.log("Connection opened", conn);
			conn.on("data", data => {
				newDataFromPeer(data);
			});

			conn.on("error", () => {
				setConnectedPeers(prev => {
					prev[conn.peer].disconnected = true;
					return {...prev};
				});
			});
		});

		updatesForPeers
			.pipe(
				throttleTime(100),
				withLatestFrom(connectedPeersSubject)
			)
			.subscribe(values => {
				let update = values[0];
				let connections = values[1];
				Object.entries(connections).forEach(([key, value]) => {
					if (value.connection !== undefined && value.connection.open) {
						value.connection.send({
							id: p.id,
							x: update.x,
							y: update.y,
						});
					}
				});
			});

		interval(10000)
			.pipe(withLatestFrom(connectedPeersSubject))
			.subscribe(values => {
				let connections = values[1];
				let idsToRemove = Object.values(connections)
					.filter(c => c.id !== p.id)
					.filter(c => c.connection === undefined || !c.connection.open)
					.map(c => c.id);
				if (idsToRemove.length > 0) {
					removeDisconnectedPeers(whiteboardId, idsToRemove);
					for (let id of idsToRemove) {
						newDataFromPeer({
							id: id,
							delete: true,
						});
					}
				}
			});

	}, []);

	useEffect(() => {
		if (peer === undefined) {
			return;
		}
		createPeerUpdatesObservable(whiteboardId)
			.subscribe(newPeers => {
				console.log("Received new peers from database", newPeers);
				setConnectedPeers(prev => {
					for (let key of Object.keys(newPeers)) {
						if (prev[key] && prev[key].connection) {
							newPeers[key].connection = prev[key].connection;
						}
					}
					return ({...newPeers});
				});
			});
	}, [peer, setConnectedPeers]);

	useEffect(() => {
		console.log("Current peers", connectedPeers);
		Object.entries(connectedPeers)
			.forEach(([key, value]) => {
				if (value.connection === undefined && key !== peer.id) {
					let connection = peer.connect(key);
					connection.on("open", () => {
						setConnectedPeers(prev => {
							prev[key].connection = connection;
							return prev;
						});
					});
				}
			});
		connectedPeersSubject.next(connectedPeers);
	}, [connectedPeers, setConnectedPeers]);
}