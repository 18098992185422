import React, {useCallback, useEffect, useState} from "react";
import {Editor} from "./editor/Editor";
import {combineLatest, Subject} from "rxjs";
import {createServerUpdatesObservable, updateRemoteData} from "./datastore/firebase";
import {map, multicast, startWith, tap, throttleTime, withLatestFrom} from "rxjs/operators";
import Automerge from "automerge";
import {usePeer2Peer} from "./peer2peer/peer2peer";

const lineSubject = new Subject();
const localDataSubject = new Subject();
const finalDataSubject = new Subject();
const peerSubject = new Subject();
const peerUpdateSubject = new Subject();

export function Whiteboard(props) {
	const whiteboardId = props.match.params.whiteboardId;
	const [localData, setLocalData] = useState(null);
	const [mousePointers, setMousePointers] = useState({});

	let newDataFromPeer = useCallback(data => peerSubject.next(data), []);
	usePeer2Peer(whiteboardId, newDataFromPeer, peerUpdateSubject);

	useEffect(() => {
		localDataSubject.next(localData);
	}, [localData]);

	useEffect(() => {
		console.log("Initializing RxJS");
		let lineUpdates = lineSubject.pipe(
			startWith(null),
			tap(v => console.log("Element received.")),
			withLatestFrom(
				localDataSubject.pipe(
					startWith(null)
				)
			),
			map(value => {
				if (value[0] === null) {
					return value[1];
				}
				return Automerge.change(value[1], doc => {
					switch (value[0].command) {
						case "CREATE": {
							doc.lines.push(value[0].data);
							break;
						}
						case "CHANGE": {
							let index = doc.lines.findIndex(line => line.id === value[0].data.id);
							Object.assign(doc.lines[index], value[0].data);
							break;
						}
						case "REMOVE": {
							let index = doc.lines.findIndex(line => line.id === value[0].data.id);
							doc.lines.splice(index, 1);
							break;
						}
						default:
							throw new Error("Not implemented", value[0].command);
					}

					if (doc.lines === undefined) {
						console.log("lines undefined");
						doc.lines = [];
					}

					return doc;
				});
			}),
			tap(v => console.log("New local update.", v))
		);
		let serverUpdates = createServerUpdatesObservable(whiteboardId)
			.pipe(
				map(value => {
					if (value === null) {
						return null;
					}
					return Automerge.applyChanges(Automerge.init(), JSON.parse(value));
				})
			);

		let multicasted = combineLatest([serverUpdates, lineUpdates])
			.pipe(
				map(value => {
					let serverDoc = value[0];
					let localDoc = value[1];
					if (serverDoc === null) {
						return localDoc;
					}
					if (localDoc === null) {
						return Automerge.applyChanges(Automerge.init(), Automerge.getAllChanges(serverDoc));
					}
					return Automerge.merge(localDoc, serverDoc);
				}),
				tap(v => console.log("Combined state", v)),
				multicast(finalDataSubject)
			);
		multicasted.subscribe(result => {
			setLocalData(result);
		});
		multicasted
			.pipe(
				throttleTime(300),
				withLatestFrom(serverUpdates)
			)
			.subscribe(value => {
				let localDoc = value[0];
				let serverDoc = value[1];
				if (serverDoc === null) {
					// Initialize database
					let serializedChanges = JSON.stringify(Automerge.getAllChanges(Automerge.from({lines: []})));
					console.log("Initializing database");
					updateRemoteData(whiteboardId, serializedChanges);
				} else if (Automerge.getChanges(serverDoc, localDoc).length > 0) {
					let serializedChanges = JSON.stringify(Automerge.getAllChanges(localDoc));
					console.log("Sending changes to database");
					updateRemoteData(whiteboardId, serializedChanges);
				}
			});
		let subscription = multicasted.connect();

		let peerSubscription = peerSubject.subscribe(data => {
			setMousePointers(prev => {
				if (data.delete) {
					delete prev[data.id];
				} else {
					prev[data.id] = data;
				}
				return {...prev};
			});
		});

		return () => {
			subscription.unsubscribe();
			peerSubscription.unsubscribe();
		};
	}, []);
	return (
		<>
			{localData === null &&
			"Loading whiteboard..."}
			{localData !== null &&
			<Editor
				data={localData}
				mousePointers={mousePointers}
				addLine={line => lineSubject.next({
					command: "CREATE",
					data: line
				})}
				addText={text => lineSubject.next({
					command: "CREATE",
					data: text
				})}
				onChange={newShapeData => {
					console.log("Change", newShapeData);
					lineSubject.next({
						command: "CHANGE",
						data: newShapeData
					});
				}}
				removeElement={id => {
					if (id === null) return;
					console.log("Remove", id);
					lineSubject.next({
						command: "REMOVE",
						data: {
							id: id
						}
					});
				}}
				updateMousePosition={pos => {
					peerUpdateSubject.next(pos);
				}}
			/>}
		</>
	);
}