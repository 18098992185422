import React, {useCallback, useEffect, useRef, useState} from "react";
import {Text} from "react-konva";
import Portal from "./Portal";
import {TransformerElement} from "./TransformerElement";

export function TextElement({data: shapeProps, mode, isSelected, deselect, onClick, onChange}) {
	let shapeRef = useRef(null);
	let [text, setText] = useState(shapeProps.text);

	useEffect(() => {
		if (!isSelected && text !== shapeProps.text) {
			onChange({
				...shapeProps,
				text: text
			});
		}
	}, [isSelected]);

	const startDragging = useCallback((e) => {
		console.log("startDragging", e);
	}, []);

	const stopDragging = useCallback((e) => {
		console.log("stopDragging", e);
		onChange({...shapeProps, x: e.target.x(), y: e.target.y()});
	}, [onChange]);

	let stopTransforming = useCallback((e) => {
		let changes = {
			type: shapeProps.type,
			id: shapeProps.id,
			x: e.target.x(),
			y: e.target.y(),
			scaleX: e.target.scaleX(),
			scaleY: e.target.scaleY(),
			rotation: e.target.rotation(),
		};
		console.log("Stop Transforming", e.target.attrs, changes);
		onChange(changes);
	}, [onChange, shapeRef]);
	return (
		<>
			<Text
				ref={shapeRef}
				id={shapeProps.id}
				x={shapeProps.x}
				y={shapeProps.y}
				scaleX={shapeProps.scaleX}
				scaleY={shapeProps.scaleY}
				rotation={shapeProps.rotation}
				text={shapeProps.text}
				draggable={mode === "move"}
				onClick={onClick}
				onTap={onClick}
				onDragStart={startDragging}
				onDragEnd={stopDragging}
				onTransformEnd={stopTransforming}
				hitStrokeWidth={10}
				fontSize={20}
			/>
			{isSelected && mode === "text" &&
			<Portal>
				<textarea
					value={text}
					style={{
						position: "absolute",
						top: shapeProps.y,
						left: shapeProps.x,
						width: "100px"
					}}
					autoFocus
					onChange={e => {
						setText(e.target.value);
					}}
					// onBlur={() => deselect()}
				/>
			</Portal>}
			{isSelected && <TransformerElement ref={shapeRef}/>}
		</>
	);
}