import React from "react";
import ReactDOM from "react-dom";
import {App} from "./components/App";

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

ReactDOM.render(<App/>, document.getElementById("app"));



