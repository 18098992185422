import React from "react";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/analytics";
import "firebase/performance";

import {object} from "rxfire/database";
import {map} from "rxjs/operators";

const firebaseConfig = {
	apiKey: "AIzaSyB_eu6PzhB6i9ZVDWJQQqlYPA7C99m8du0",
	authDomain: "whiteboard-1275f.firebaseapp.com",
	databaseURL: "https://whiteboard-1275f.firebaseio.com",
	projectId: "whiteboard-1275f",
	storageBucket: "whiteboard-1275f.appspot.com",
	messagingSenderId: "724008200398",
	appId: "1:724008200398:web:ec3766f9a873c625d4c89f",
	measurementId: "G-SLGVZXGE01"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);
if (location.hostname === "localhost") {
	firebase.database().useEmulator("localhost", 9001);
}
let database = firebase.database();
const perf = firebase.performance();
console.log(`Performance: ${perf.dataCollectionEnabled}, ${perf.instrumentationEnabled}`);

export function createServerUpdatesObservable(id) {
	return object(database.ref(`whiteboard/${id}/shapes`))
		.pipe(
			map(change => {
				let store = change.snapshot.val();
				if (store === null || store.data === undefined) {
					return null;
				} else {
					return store.data;
				}
			})
		);
}

export function updateRemoteData(id, changes) {
	database.ref(`whiteboard/${id}/shapes`)
		.transaction(store => {
			if (store == null) {
				store = {
					data: null
				};
				store.data = changes;
			} else {
				store.data = changes;
			}
			store.timestamp = firebase.database.ServerValue.TIMESTAMP;
			return store;
		})
		.then(res => {
			console.log("Update successful", res);
		})
		.catch(err => {
			console.error("Data update failed", err);
		});
}

export function createPeerUpdatesObservable(id) {
	return object(database.ref(`whiteboard/${id}/peers`))
		.pipe(
			map(peers => {
				return peers.snapshot.val();
			})
		);
}

export function updatePeerId(whiteboardId, peerId) {
	database.ref(`whiteboard/${whiteboardId}/peers`)
		.transaction(peers => {
			if (peers == null) {
				peers = {};
			}

			let existingPeer = peers[peerId];
			if (existingPeer !== undefined) {
				peers[peerId].timestamp = firebase.database.ServerValue.TIMESTAMP;
			} else {
				peers[peerId] = {
					id: peerId,
					timestamp: firebase.database.ServerValue.TIMESTAMP,
				};
			}
			console.log("Setting peers", peers);
			return peers;
		})
		.then(res => {
			console.log("Added peer successfully", res);
		})
		.catch(err => {
			console.error("Add peer failed", err);
		});
}

export function removeDisconnectedPeers(whiteboardId, peersToRemove) {
	console.log("Removing disconnected peers", peersToRemove);

	for (let peerId of peersToRemove) {
		database.ref(`whiteboard/${whiteboardId}/peers/${peerId}`).remove(() => console.log("Removed " + peerId));
	}
}